import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row } from 'react-bootstrap';
import { css } from '@emotion/css'

import { SmallSpinner } from '@jasperoosthoek/react-toolbox'
import { use } from '../../redux/Factory';
import { getBaseUrl } from '../../utils/Utils';

const ThumbnailsGallery = ({ type }) => {
  const { pagesList } = use.pages({ type });
  // Use display flex to align cards correctly: https://www.codeply.com/go/KppyJTTbwM
  // Remove gutter space for rows: https://getbootstrap.com/docs/5.0/layout/gutters/
  return (
    <Row className="display-flex g-0">
      {pagesList && Object.values(pagesList).map(({ thumbnail, type, slug, title, description }, key) =>
        <Card
          key={key}
          className={css`
            display: inline-block;
            max-width: 400px; 
            margin: 12px;
            border: 0px;
          ` + ' float-start'}
        >
          <Link to={`/${type}/${slug}`}>
            <Card.Img variant="top" src={`${getBaseUrl()}/static/${thumbnail}`}
            className={css`
              max-height: 500px; 
              max-width: 400px; 
              box-shadow: 0 1.375rem 2.25rem -0.75rem rgb(64 64 64 / 13%);
            `}/>
          </Link>
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>{description}</Card.Text>
          </Card.Body>
        </Card>
      )}
    </Row>
  );
};



const Home = () => {
  const { getPagesList, pagesList } = use.pages();
  useEffect(() => {
    getPagesList({ params: { type: 'analysis' } });
  }, [])

  if (getPagesList.isLoading || !pagesList || !pagesList['analysis']) return <SmallSpinner />;

  return <ThumbnailsGallery type='analysis' />;
};

export default Home;